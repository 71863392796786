import { FilteredCreditApplication } from '@src/api/credit-api'
import {
  applicantFullName,
  areAllDocumentsReadyForCVT,
  hasMissingDocuments,
} from '@src/data/credit-application-selectors'
import { useTranslation } from 'react-i18next'
import { EApplicantType, EWorksheetStatus } from '@src/types'
import { useMemo } from 'react'
import { useRequiredDocumentsWithStatus } from '@src/data/credit-application-hooks'
import CreditStep, { CreditStepStatus } from './CreditStep'
import { contractIsReceived } from '../../hooks'

type Props = {
  creditApp: FilteredCreditApplication
}

const WorksheetCreditStep = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const requiredDocumentsWithStatus = useRequiredDocumentsWithStatus(creditApp.requiredDocuments, creditApp.documents)

  const { status, content, link } = useMemo<{ status: CreditStepStatus; content?: string; link?: string }>(() => {
    if (hasMissingDocuments(creditApp)) return { status: CreditStepStatus.NotReady }

    if (
      !creditApp.allIncomesConfirmed ||
      !creditApp.allTaskCompletedForCVT ||
      !areAllDocumentsReadyForCVT(requiredDocumentsWithStatus)
    )
      return { status: CreditStepStatus.Await, content: t('overview.worksheetWaitContract') }

    if (contractIsReceived(EApplicantType.Applicant, creditApp)) return { status: CreditStepStatus.Completed }

    return {
      status: CreditStepStatus.RequiresAttention,
      content: t('overview.worksheetContent'),
      link: creditApp.worksheet?.status === EWorksheetStatus.Draft ? 'worksheet' : 'contract',
    }
  }, [creditApp, requiredDocumentsWithStatus, t])

  return (
    <CreditStep
      status={status}
      linkToDetail={link ?? ''}
      title={t('worksheet.title', { name: applicantFullName(creditApp.applicant) })}
      content={content ?? ''}
      hideLinkToDetail={status !== CreditStepStatus.RequiresAttention}
    />
  )
}

export default WorksheetCreditStep
