import { FilteredCreditApplication } from '@src/api/credit-api'
import { scrollToTop } from '@src/services/utils'
import { EApplicantType, RequiredDocumentWithStatus } from '@src/types'
import { useLayoutEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import WorksheetPage from '@src/containers/Worsheet/WorksheetPage'
import ApplicantIncomePage from './components/ApplicantIncomePage'
import CreditSummaryPage from './components/CreditSummaryPage'
import CustomerFeedbackPage from './components/CustomerFeedbackPage'
import DocumentsPage from './components/DocumentsPage'
import OverviewPage from './OverviewPage/OverviewPage'

type Props = {
  creditApp: FilteredCreditApplication
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
}

const HardHitApprovedResultPage = ({ creditApp, skipBankAccountRequiredDocuments }: Props) => {
  useLayoutEffect(scrollToTop, [])

  return (
    <Routes>
      <Route path="credit-summary" element={<CreditSummaryPage creditApp={creditApp} />} />
      <Route
        path="applicant-incomes"
        element={
          <ApplicantIncomePage
            creditApp={creditApp}
            applicantType={EApplicantType.Applicant}
            skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments}
          />
        }
      />
      <Route
        path="coapplicant-incomes"
        element={
          <ApplicantIncomePage
            creditApp={creditApp}
            applicantType={EApplicantType.CoApplicant}
            skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments}
          />
        }
      />
      <Route path="required-documents" element={<DocumentsPage creditApp={creditApp} />} />

      <Route path="worksheet/:step?" element={<WorksheetPage creditApp={creditApp} />} />

      <Route path="feed-back" element={<CustomerFeedbackPage creditApp={creditApp} />} />

      <Route
        index
        path="*"
        element={
          <OverviewPage creditApp={creditApp} skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments} />
        }
      />
    </Routes>
  )
}

export default HardHitApprovedResultPage
