import { SubmitPrequalificationDto, useSubmitPrequalification } from '@src/api/credit-api'
import { useMerchantById } from '@src/api/merchants-api'
import { AcceptTermsCheckbox, Alert, AsyncActionButton } from '@src/components'
import { isDefaultMerchant } from '@src/data/merchant-selectors'
import { FormatCurrency } from '@src/services/Formatter'
import { reportErrorToServer } from '@src/services/error-logger'
import { AxiosError } from 'axios'
import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ValidationError from 'yup/lib/ValidationError'
import { useAppStore } from '@src/data/AppContext'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { getRecaptchaToken } from '@src/services/recaptcha'
import PromoCodeInput from '@src/components/PromoCodeInput'
import { Prequalification, PrequalificationSchema } from './PrequalificationSchema'
import ReviewApplicantInfo from './ReviewApplicantInfo'

type Props = {
  gotoStep: (step: string) => void
  prequalification: Prequalification
}

const ReviewPrequalification = ({ prequalification, gotoStep }: Props) => {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const navigate = useNavigate()

  const [promoCodeIsValidating, setPromoCodeIsValidating] = useState<boolean>()
  const [merchantPaymentPlanId, setMerchantPaymentPlanId] = useState<string | null>(null)
  const [submitPrequalification, isSubmitting, isSuccess] = useSubmitPrequalification()
  const [merchant] = useMerchantById(prequalification.merchantId)
  const [hasLoanAmountError, setHasLoanAmountError] = useState<boolean>(false)
  const [hasLoanPurposeError, setHasLoanPurposeError] = useState<boolean>(false)
  const [hasApplicantError, setHasApplicantError] = useState<boolean>(false)
  const [hasApplicantAddressError, setHasApplicantAddressError] = useState<boolean>(false)
  const [hasApplicantRevenueError, setHasApplicantRevenueError] = useState<boolean>(false)
  const [hasFormError, setHasFormError] = useState<boolean>(false)
  const appStore = useAppStore()
  const shouldDisplayAnyPromoCodeRelatedInfo = merchant?.supportsPromotions === true
  const { applicant } = prequalification

  const handleOnPaymentPlanIdChange = useCallback(
    (paymentPlanId: string | null) => setMerchantPaymentPlanId(paymentPlanId),
    [],
  )

  const merchantIsIfinanceMedicard = isDefaultMerchant(prequalification.merchantId)

  const prequalificationDataContainsErrors = async () => {
    setHasLoanAmountError(false)
    setHasLoanPurposeError(false)
    setHasApplicantError(false)
    setHasApplicantAddressError(false)
    setHasApplicantRevenueError(false)
    setHasFormError(false)
    let formHasError = false
    try {
      await PrequalificationSchema.validate(prequalification, { abortEarly: false })
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((err) => {
          const path = err.path as string
          if (path.includes('requestedLoanAmount')) {
            setHasLoanAmountError(true)
            formHasError = true
          }
          if (path.includes('loanPurposeId')) {
            setHasLoanPurposeError(true)
            formHasError = true
          }
          if (path.includes('applicant')) {
            setHasApplicantError(true)
            formHasError = true
          }
          if (path.includes('applicant.currentAddress')) {
            setHasApplicantAddressError(true)
            formHasError = true
          }
          if (path.includes('applicant.currentJobs[0]')) {
            setHasApplicantRevenueError(true)
            formHasError = true
          }
        })
      }
    }
    if (formHasError) {
      setHasFormError(formHasError)
      window.scrollTo(0, 0)
    }
    return formHasError
  }

  const buildDtoAndSubmitPrequal = async (evt: { preventDefault: () => void }) => {
    evt.preventDefault()

    if ((await prequalificationDataContainsErrors()) === false) {
      try {
        const recaptchaToken = await getRecaptchaToken(executeRecaptcha, 'submit_postCreditApplicationAndPrequalify')
        const dto: SubmitPrequalificationDto = {
          recaptchaToken,
          prequalification: {
            ...prequalification,
            origination: appStore.origination,
            merchantPaymentPlanId,
          },
        }
        const creditApp = await submitPrequalification(dto)
        navigate(`/creditApplication/${creditApp.id}`, { replace: true })
      } catch (error) {
        if ((error as AxiosError).response?.status === 424) {
          navigate('/recaptcha-validation-failed')
        } else {
          reportErrorToServer(error as Error)
        }
      }
    }
  }

  return (
    <section className="step-content">
      <h3 className="form-question">{t('review.title')}</h3>
      <div className="paragraph">
        <p>{t('review.description')}</p>
      </div>

      <form onSubmit={buildDtoAndSubmitPrequal}>
        {hasFormError && <Alert type="error" message={t('review.formContainsErrors')} />}
        <div className="review-wrap">
          <div className="review-item">
            <h1 className="h3">{t('review.desiredLoanAmount')}</h1>
            <div className="box">
              <div className="box-content">
                <p>
                  <strong>{t('loanAmount.amount')} : </strong>
                  <span>{FormatCurrency(prequalification.requestedLoanAmount)}</span>
                </p>
              </div>
              <button
                type="button"
                className={hasLoanAmountError ? 'btn-error btn-simple-error' : 'btn-action btn-simple'}
                onClick={() => gotoStep('loan-amount')}
              >
                {t('review.modify')}
              </button>
            </div>
          </div>

          {merchantIsIfinanceMedicard && (
            <div className="review-item">
              <h1 className="h3">{t('common.loanPurpose')}</h1>
              <div className="box">
                <div className="box-content">
                  <p>
                    <strong>{t('common.loanPurpose')} : </strong>
                    <span>{t(`enum.eLoanPurpose.${prequalification.loanPurposeId}`)}</span>
                  </p>
                  <p>
                    <strong>{t('common.serviceProvider')} : </strong>
                    <span>{prequalification.merchantName ? prequalification.merchantName : t('common.none')}</span>
                  </p>
                </div>
                <button
                  type="button"
                  className={hasLoanPurposeError ? 'btn-error btn-simple-error' : 'btn-action btn-simple'}
                  onClick={() => gotoStep('loan-purpose')}
                >
                  {t('review.modify')}
                </button>
              </div>
            </div>
          )}

          <ReviewApplicantInfo
            applicant={applicant}
            hasApplicantError={hasApplicantError}
            hasApplicantAddressError={hasApplicantAddressError}
            hasApplicantRevenueError={hasApplicantRevenueError}
            gotoStep={gotoStep}
          />

          {shouldDisplayAnyPromoCodeRelatedInfo && (
            <PromoCodeInput
              merchantId={merchant.id}
              onChange={handleOnPaymentPlanIdChange}
              setValidating={setPromoCodeIsValidating}
            />
          )}
        </div>
        <footer className="review-footer">
          <h3>
            <Trans i18nKey="confirmation.consentTitle" />
          </h3>

          <AcceptTermsCheckbox
            msgOnInvalid={t('common.acceptConditions')}
            id="acceptConditions"
            termId={t('confirmation.consent')}
          />

          <AcceptTermsCheckbox
            msgOnInvalid={t('common.acceptConditions')}
            id="acceptAuthorise"
            termId={t('common.acceptAuthorization')}
          >
            <br />
            {t('confirmation.authorization')}
            <u>
              <a href={t('common.policyLink')} target="_blank" rel="noreferrer">
                {t('contact.linkText')}
              </a>
            </u>
            {t('confirmation.authorizationEnd')}
          </AcceptTermsCheckbox>
          <AsyncActionButton
            type="submit"
            className="btn btn-blue"
            disabled={isSubmitting || promoCodeIsValidating || hasFormError}
            isPending={isSubmitting || isSuccess}
            primary
          >
            <span>{t('review.getQuote')}</span>
            <i className="fa-regular fa-arrow-right" />
          </AsyncActionButton>
        </footer>
      </form>
    </section>
  )
}

export default ReviewPrequalification
