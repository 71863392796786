/* eslint-disable react/no-array-index-key */

import {
  AddressSuggestionDto,
  SelectedAddress,
  SuggestionsResponseDto,
  useAddressSuggestion,
} from '@src/api/address-api'
import { reportErrorToConsole } from '@src/services/error-logger'
import { getRecaptchaToken } from '@src/services/recaptcha'
import { debounce } from 'lodash'
import { KeyboardEventHandler, useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'

type Props = {
  setShowAutoCompleteAddressInput: React.Dispatch<React.SetStateAction<boolean>>
  selectedAddress: SelectedAddress | null
  setSelectedAddress: React.Dispatch<React.SetStateAction<SelectedAddress | null>>
  onChangeAddress: (address: SelectedAddress) => Promise<void>
  fullAddressDisplay: string
}

const AddressAutoCompleteInput = ({
  setShowAutoCompleteAddressInput,
  selectedAddress,
  setSelectedAddress,
  onChangeAddress,
  fullAddressDisplay,
}: Props) => {
  const { t } = useTranslation()
  const ADDRESS_DROP_DOWN_LIMIT = 5
  const [addressInput, setAddressInput] = useState<string | null>(null)
  const [suggestionsList, setSuggestionsList] = useState<string[]>([])
  const [currentSuggestionDto, setCurrentSuggestionDto] = useState<SuggestionsResponseDto | null>(null)
  const [focusedItem, setFocusedItem] = useState<number | null>(null)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [addressSuggestion, isLoadingSuggestion] = useAddressSuggestion()

  const getFilteredInputLength = (inputValue: string) => {
    return inputValue.replaceAll(' ', '').length
  }

  const setAddressInputCallback = useCallback(
    async (inputValue: string) => {
      setFocusedItem(null)
      const recaptchaToken = await getRecaptchaToken(executeRecaptcha, 'submit_addressSuggestions')
      const dto: AddressSuggestionDto = {
        addressInput: inputValue,
        recaptchaToken,
      }
      if (getFilteredInputLength(inputValue) > 3) {
        const response = await addressSuggestion(dto)
        setCurrentSuggestionDto(response)
        const list = response.suggestions.map((x) => x.addressSuggestion)
        setSuggestionsList(list)
        setFocusedItem(0)
      }
    },
    [executeRecaptcha, addressSuggestion],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateAddress = useCallback(debounce(setAddressInputCallback, 1000), [setAddressInputCallback])

  const handleAddressChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value
      setSelectedAddress(null)
      setAddressInput(inputValue)

      if (inputValue) {
        await debouncedUpdateAddress(inputValue)
      } else {
        setAddressInput('')
        setSelectedAddress(null)
        setSuggestionsList([])
      }
    },
    [debouncedUpdateAddress, setSelectedAddress],
  )

  const handleSuggestionClick = async (suggestion: string) => {
    setAddressInput(suggestion)
    if (currentSuggestionDto) {
      const selectedSuggestion = currentSuggestionDto.suggestions.find((item) => item.addressSuggestion === suggestion)
      if (selectedSuggestion) {
        const res: SelectedAddress = {
          placeId: selectedSuggestion.placeId,
        }
        await onChangeAddress(res)
        setSelectedAddress(res)
      }
    }
    setSuggestionsList([])
  }

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (focusedItem !== null) {
        handleSuggestionClick(suggestionsList[focusedItem]).catch(reportErrorToConsole)
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()

      setFocusedItem((fi) => {
        if (fi !== null && fi < ADDRESS_DROP_DOWN_LIMIT - 1) {
          return fi + 1
        }
        return fi
      })
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setFocusedItem((fi) => {
        if (fi !== null && fi > 0) {
          return fi - 1
        }
        return fi
      })
    }
  }

  return (
    <div className="control-group">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <label htmlFor="fullAddress">{t('addressAutoComplete.enterAddress')}</label>
        <button
          className="btn btn-underline"
          style={{ marginLeft: 'auto', fontSize: '85%' }}
          onClick={() => setShowAutoCompleteAddressInput(false)}
        >
          {t('addressAutoComplete.cantFind')}
          <span className="help-tip-exclamation" style={{ marginLeft: '1px' }}>
            <p>{t('addressAutoComplete.cantFindToolTip')}</p>
          </span>
        </button>
      </div>
      <div className="input-with-icon">
        {selectedAddress !== null && !isLoadingSuggestion && <i className="fa fa-check icon" />}
        {isLoadingSuggestion && <i className="fa fa-spinner fa-spin icon" style={{ top: '35%' }} />}
        <input
          type="text"
          id="fullAddress"
          name="fullAddress"
          maxLength={100}
          aria-label="Address search input"
          value={addressInput === '' ? addressInput : addressInput || fullAddressDisplay}
          onChange={handleAddressChange}
          autoComplete="off"
          placeholder={t('addressAutoComplete.placeHolder')}
          onKeyDown={handleKeyPress}
        />
      </div>

      <div>
        {suggestionsList.length > 0 && (
          <div className="control-group center" style={{ padding: '0' }}>
            <ul className="merchant-list" style={{ cursor: 'pointer' }}>
              {suggestionsList.slice(0, ADDRESS_DROP_DOWN_LIMIT).map((suggestion, index) => (
                <li key={index}>
                  <button
                    type="button"
                    className={`merchant-list-item ${index === focusedItem ? 'focused' : ''}`}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <h3>{suggestion}</h3>
                  </button>
                </li>
              ))}
            </ul>
            {selectedAddress === null && suggestionsList.length > ADDRESS_DROP_DOWN_LIMIT && (
              <button className="merchant-list-footer">
                <h3 style={{ fontSize: '1.75rem' }}>
                  {t('serviceProvider.serviceProviderSearch.resultsFound', {
                    merchantsFound:
                      suggestionsList.length > ADDRESS_DROP_DOWN_LIMIT
                        ? ADDRESS_DROP_DOWN_LIMIT
                        : suggestionsList.length,
                  })}
                </h3>
                <p style={{ fontSize: '1.5rem', marginTop: '1rem', cursor: 'pointer' }}>
                  {t('addressAutoComplete.refineSearch')}
                </p>
              </button>
            )}
            {selectedAddress === null &&
              suggestionsList.length > 0 &&
              suggestionsList.length <= ADDRESS_DROP_DOWN_LIMIT && (
                <button className="merchant-list-footer">
                  <h3 style={{ fontSize: '1.75rem' }}>{t('addressAutoComplete.pickOption')}</h3>
                </button>
              )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AddressAutoCompleteInput
