import ReactGA from 'react-ga4'

export default function initializeGAnalytics(): void {
  const USED_GOOGLE_MEASURE_ID: string | undefined = window.location.href.includes('ifinancecanada')
    ? import.meta.env.VITE_IFI_GOOGLE_MEASURE_ID
    : import.meta.env.VITE_MED_GOOGLE_MEASURE_ID
  if (!USED_GOOGLE_MEASURE_ID) console.warn('GAnalytics init failed')
  else {
    ReactGA.initialize(USED_GOOGLE_MEASURE_ID)
  }
}
