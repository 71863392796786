import React, { ReactNode } from 'react'

type Props = {
  id: string
  termId?: string
  msgOnInvalid: string
  children?: ReactNode
}

const AcceptTermsCheckbox: React.FC<Props> = ({ id, children, termId, msgOnInvalid }) => {
  return (
    <div className="control-group">
      <input
        required
        type="checkbox"
        id={id}
        onChange={(e) => {
          if (e.currentTarget.checked === true) e.currentTarget.setCustomValidity('')
        }}
        onInvalid={(e) => e.currentTarget.setCustomValidity(msgOnInvalid)}
      />
      <label style={{ fontSize: '1.8rem', fontWeight: 'inherit' }} htmlFor={id}>
        {termId && termId}
        {children}
      </label>
    </div>
  )
}

export default AcceptTermsCheckbox
