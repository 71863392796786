import ReactPixel from 'react-facebook-pixel'

export default function initializeFPixel(): void {
  const USED_FPIXEL_MEASURE_ID: string | undefined = window.location.href.includes('ifinancecanada')
    ? import.meta.env.VITE_IFI_FPIXEL_MEASURE_ID
    : import.meta.env.VITE_MED_FPIXEL_MEASURE_ID
  if (!USED_FPIXEL_MEASURE_ID) console.warn('FPixel init failed')
  else {
    ReactPixel.init(USED_FPIXEL_MEASURE_ID)
    ReactPixel.pageView()
  }
}
