import { yupResolver } from '@hookform/resolvers/yup'
import { range } from 'lodash-es'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AddressDetailDto, SelectedAddress, useAddressDetail } from '@src/api/address-api'
import { formatAddress } from '@src/services/Formatter'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { getRecaptchaToken } from '@src/services/recaptcha'
import { Alert } from '../../../components'
import { helpTip, setYearMonthValueFromMonths } from '../../../services/utils'
import { EHomeFeeType, EHomeFeeTypeList, EProvince, EProvinceList } from '../../../types'
import { HomeInformationInput, HomeInformationInputSchema } from './PrequalificationSchema'
import { FORM_ID } from './StepperFormFooter'
import AddressAutoCompleteInput from './AddressAutoCompleteInput'

declare global {
  interface Window {
    initAutocomplete: () => void
  }
}

type Props = {
  applicantName: string
  applicantData: HomeInformationInput
  forceProvince?: EProvince
  onApplicantUpdated: (data: HomeInformationInput) => void
  setDisableNext: (disable: boolean) => void
}

const AddressForm = ({ forceProvince, applicantData, onApplicantUpdated, applicantName, setDisableNext }: Props) => {
  const {
    t,
    i18n: { resolvedLanguage: lang },
  } = useTranslation()
  const yearSelectRef = React.useRef<HTMLSelectElement>(null)
  const monthSelectRef = React.useRef<HTMLSelectElement>(null)
  const [showAlert, setShowAlert] = useState(false)
  const [isEqualError, setIsEqualError] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddress | null>(null)
  const [showAutoCompleteAddressInput, setShowAutoCompleteAddressInput] = useState(true)
  const [addressDetail] = useAddressDetail()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    trigger,
  } = useForm<HomeInformationInput>({
    mode: 'onBlur',
    resolver: yupResolver(HomeInformationInputSchema),
    defaultValues: applicantData,
  })

  React.useEffect(() => {
    if (applicantData.currentAddress.months > 0)
      setYearMonthValueFromMonths(
        applicantData.currentAddress.months,
        yearSelectRef.current as HTMLSelectElement,
        monthSelectRef.current as HTMLSelectElement,
      )
    helpTip()
  }, [applicantData.currentAddress.months, trigger])

  const homeFeeTypeId = watch('expenses.homeFeeTypeId')

  const totalMonthlyHomePayment = watch('expenses.totalMonthlyHomePayment')

  const civicNumber = watch('currentAddress.civicNumber')
  const apartmentNumber = watch('currentAddress.apartment')

  React.useEffect(() => {
    if (homeFeeTypeId === EHomeFeeType.Own) {
      setValue('expenses.totalMonthlyHomePayment', 0, { shouldValidate: true, shouldTouch: true, shouldDirty: true })
    }
  }, [homeFeeTypeId, setValue])

  React.useEffect(() => {
    if (totalMonthlyHomePayment > 0 && homeFeeTypeId === EHomeFeeType.Own) {
      setValue('expenses.homeFeeTypeId', EHomeFeeType.OwnWithMortgage, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
    }
  }, [homeFeeTypeId, setValue, totalMonthlyHomePayment])

  const onChangeMonth = () => {
    const year = +(yearSelectRef.current?.value ?? 0)
    const month = +(monthSelectRef.current?.value ?? 0)

    const months = year * 12 + month
    setValue('currentAddress.months', months, { shouldValidate: true, shouldTouch: true, shouldDirty: true })

    if (months > 0) clearErrors('currentAddress.months')
  }

  const equalNumbers = useMemo(() => {
    return (
      civicNumber === apartmentNumber &&
      civicNumber !== '' &&
      apartmentNumber !== '' &&
      civicNumber !== undefined &&
      apartmentNumber !== undefined
    )
  }, [civicNumber, apartmentNumber])

  React.useEffect(() => {
    setDisableNext(equalNumbers)
    setShowAlert(true)
    setIsEqualError(equalNumbers)
  }, [equalNumbers, setDisableNext])

  const handleClearFields = () => {
    setShowAlert(false)
  }

  const onChangeAddress = async (address: SelectedAddress) => {
    if (!address) return
    const recaptchaToken = await getRecaptchaToken(executeRecaptcha, 'submit_addressDetail')
    const dto: AddressDetailDto = {
      placeId: address.placeId,
      recaptchaToken,
      userLanguageCode: lang as string,
    }

    const ret = await addressDetail(dto)

    const currentAddress = {
      civicNumber: ret.address.civicNumber,
      street: ret.address.street,
      apartment: ret.address.apartment ?? '',
      postalCode: ret.address.postalCode,
      city: ret.address.city,
      stateIso: ret.address.stateIso,
      country: ret.address.country,
    }

    Object.entries(currentAddress).forEach(([key, value]) => {
      setValue(`currentAddress.${key as keyof typeof currentAddress}`, value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    })
  }

  const hasAddressAutoCompleteInputError = () => {
    if (!errors.currentAddress) return false

    const fieldsToCheck: Array<keyof typeof errors.currentAddress> = [
      'civicNumber',
      'street',
      'postalCode',
      'city',
      'stateIso',
      'country',
    ]

    return fieldsToCheck.some((field) => errors.currentAddress?.[field])
  }

  return (
    <section className="step-content">
      <h3 className="form-question">{t('address.provideAddress', { firstName: applicantName })}</h3>
      {equalNumbers && showAlert && (
        <Alert
          type="error"
          message={t('common.sameCivicAndApartmentNumber')}
          buttons={[
            {
              id: 'btn-dismiss',
              label: t('common.yes'),
              onClick: () => {
                setShowAlert(false)
                setDisableNext(false)
                setIsEqualError(false)
              },
            },
            { id: 'btn-correct', label: t('common.modify'), onClick: () => handleClearFields() },
          ]}
        />
      )}
      <form onSubmit={handleSubmit(onApplicantUpdated)} id={FORM_ID}>
        {showAutoCompleteAddressInput ? (
          <>
            {hasAddressAutoCompleteInputError() && (
              <Alert type="error" message={t('addressAutoComplete.refineSearch')} />
            )}
            <AddressAutoCompleteInput
              setShowAutoCompleteAddressInput={setShowAutoCompleteAddressInput}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              onChangeAddress={onChangeAddress}
              fullAddressDisplay={
                applicantData.currentAddress.civicNumber ? formatAddress(applicantData.currentAddress) : ''
              }
            />
          </>
        ) : (
          <>
            <div
              className={`control-group mid ${errors.currentAddress?.civicNumber ? 'error' : ''} ${isEqualError ? 'error' : ''}`}
            >
              <label htmlFor="civic_number">{t('address.addressNumber')}</label>
              <input type="number" id="civicNumber" {...register('currentAddress.civicNumber')} maxLength={10} />
            </div>

            <div className={`control-group mid ${errors.currentAddress?.street && 'error'}`}>
              <label htmlFor="street_name">{t('address.streetName')}</label>
              <input type="text" id="street" {...register('currentAddress.street')} maxLength={100} />
            </div>

            <div className={`control-group mid ${errors.currentAddress?.city && 'error'}`}>
              <label htmlFor="city">{t('address.city')}</label>
              <input type="text" id="city" {...register('currentAddress.city')} maxLength={50} />
            </div>

            <div className="control-group mid">
              <label htmlFor="select">{t('address.province')}</label>
              <div className={`dropdown-wrap ${errors.currentAddress?.stateIso && 'error'}`}>
                <select id="stateIso" {...register('currentAddress.stateIso')} defaultValue="">
                  {!forceProvince && (
                    <>
                      <option value="" disabled>
                        {' '}
                      </option>
                      {EProvinceList.map((value) => (
                        <option value={value} key={value}>
                          {t(`enum.eProvince.${value}`)}
                        </option>
                      ))}
                    </>
                  )}
                  {forceProvince && <option value={forceProvince}>{t(`enum.eProvince.${forceProvince}`)}</option>}
                </select>
              </div>
            </div>

            <div className={`control-group mid ${errors.currentAddress?.postalCode && 'error'}`}>
              <label htmlFor="postal_code">{t('address.postalCode')}</label>
              <input type="text" id="postalCode" {...register('currentAddress.postalCode')} maxLength={7} />
            </div>
          </>
        )}

        <div
          className={`control-group mid ${errors.currentAddress?.apartment ? 'error' : ''} ${isEqualError ? 'error' : ''}`}
        >
          <label htmlFor="apartment_number">{t('address.apartmentNumber')}</label>
          <input type="text" id="apartment" {...register('currentAddress.apartment')} maxLength={50} />
        </div>

        <div className="control-group" style={{ paddingBottom: 0 }}>
          <label htmlFor="year" style={{ marginBottom: 0 }}>
            {t('livingAtAddressSince.howLong')}
            <span className="help-tip">
              <p>{t('livingAtAddressSince.details')}</p>
            </span>
          </label>
        </div>

        <div className="control-group mid">
          <div className="dropdown-wrap">
            <select id="year" name="year" onChange={onChangeMonth} ref={yearSelectRef} defaultValue={0}>
              <option value={0}>{t('common.years')}</option>
              {range(1, 12).map((item) => (
                <option value={item} key={item}>
                  {t('common.yearsLabel', { count: item })}
                </option>
              ))}
              <option value={12}>
                {t('common.moreThan')} {t('common.yearsLabel', { count: 12 })}
              </option>
            </select>
          </div>
        </div>

        <div className="control-group mid">
          <div className={`dropdown-wrap ${errors.currentAddress?.months && 'error'}`}>
            <select id="month" name="month" onChange={onChangeMonth} ref={monthSelectRef} defaultValue={0}>
              <option value={0}>{t('common.months')}</option>
              {range(1, 12).map((item) => (
                <option value={item} key={item}>
                  {t('common.monthsLabel', { count: item })}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="control-group" style={{ paddingBottom: 0 }}>
          <label htmlFor="housing_status" style={{ marginBottom: 0 }}>
            {t('housing.currentStatus')}
            <span className="help-tip">
              <p>{t('livingAtAddressSince.details')}</p>
            </span>
          </label>
        </div>

        <div className="control-group mid">
          <label htmlFor="housing_status">{t('housing.homeFeeType')}</label>
          <div className={`dropdown-wrap ${errors.expenses?.homeFeeTypeId && 'error'}`}>
            <select {...register('expenses.homeFeeTypeId')} id="homeFeeTypeId">
              {EHomeFeeTypeList.map((item) => (
                <option key={item} value={item}>
                  {t(`enum.eHomeFeeType.${item}`)}
                </option>
              ))}
            </select>
          </div>
        </div>

        {homeFeeTypeId !== EHomeFeeType.Own && homeFeeTypeId !== EHomeFeeType.WithParents && (
          <div className={`control-group mid  ${errors.expenses?.totalMonthlyHomePayment && 'error'}`}>
            <label htmlFor="mortgage_amount">
              {t('housing.totalMonthlyPayment', { homeFeeType: t(`housing.${homeFeeTypeId}`) })}
            </label>
            <div className={`number-wrap ${lang === 'en' ? 'startwith' : 'endwith'}`}>
              <input type="number" id="totalMonthlyHomePayment" {...register('expenses.totalMonthlyHomePayment')} />
            </div>
          </div>
        )}
        {(totalMonthlyHomePayment > 0 || homeFeeTypeId === EHomeFeeType.WithParents) && (
          <div className={`control-group  ${errors.expenses?.housingMonthly && 'error'}`}>
            <label htmlFor="mortgage_payment">
              {t('housing.contributedMonthlyPayment', {
                homeFeeType: t(`housing.${homeFeeTypeId}`),
              })}
            </label>
            <div className={`number-wrap ${lang === 'en' ? 'startwith' : 'endwith'}`}>
              <input type="number" id="monthlyHomePayment" {...register('expenses.housingMonthly')} />
            </div>
          </div>
        )}
      </form>
    </section>
  )
}

export default AddressForm
