/* eslint-disable @typescript-eslint/no-unsafe-argument */
import getRuntimeVariable from './env'
import { reportErrorToServer } from './error-logger'

export const getRecaptchaToken = async (
  executeRecaptcha: ((action?: string) => Promise<string>) | undefined,
  action?: string,
): Promise<string> => {
  if (getRuntimeVariable<boolean>(import.meta.env.VITE_RECAPTCHA_ENABLED)) {
    if (!executeRecaptcha) {
      const err = new Error('ReCAPTCHA non disponible.')
      reportErrorToServer(err)
      throw err
    }

    const recaptchaToken = await executeRecaptcha(action)

    return recaptchaToken
  }

  return ''
}

export default { getRecaptchaToken }
